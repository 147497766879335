<template>
  <div class="examcenter">
    <t_header :isUserInfo="isUserInfo" @setTodos="getTodos"></t_header>
    <div class="examcenterdiv">
      <div class="examcenterdiv_tips">
        <p>在线题库 > </p>
        <el-select v-model="selectValue" placeholder="请选择" @change="selectChange(selectValue)">
          <el-option v-for="item in options" :key="item.firstId" :label="item.firstName" :value="item.firstId"></el-option>
        </el-select>
      </div>

      <div class="examcenterdiv_reveal">
        <!-- <div class="examcenterdiv_reveal_switch"> -->
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane v-for="(item,index) in second_typeList" :key="index" :label="item.secondName">
            <div class="examcenterdiv_reveal_center">
              <div>
                <el-progress type="circle" :percentage="questionBank_info.totalRatio" :format="format_one"></el-progress>
                <p>做题数{{ questionBank_info.answerNumber }}道/总题数{{ questionBank_info.countNumber }}道</p>
              </div>
              <div>
                <el-progress type="circle" :percentage="questionBank_info.accuracy" :format="format_two"></el-progress>
                <p>正确率</p>
              </div>
              <!-- <div>
                <img src="@/assets/exam/exam_icon.png" alt="">
                <p>已做{{ questionBank_info.answerPaperCount }}套/{{ questionBank_info.myPaperCount }}套试题</p>
              </div> -->
              <div>
                <img src="@/assets/exam/totalError.png" alt="">
                <p>错题总数{{ questionBank_info.errorAnswerNumber }}数</p>
              </div>
              <div>
                <img src="@/assets/exam/time_icon.png" alt="">
                <p>做题{{ questionBank_info.sumDuration }}</p>
              </div>
            </div>
          </el-tab-pane>

        </el-tabs>

        <!-- </div> -->

      </div>

      <div class="examcenterdiv_center">
        <div class="examcenterdiv_center_left">
          <div class="examcenterdiv_center_left_tab">
            <div class="examcenterdiv_center_left_tab_item" v-if="selectfirstId == 245" v-for="(item,index) in sidebarList" :key="index" @click="tabChange(item)" :class="tabIndex==item.id?'tabIndex':''">
              <img src="@/assets/exam/chapter.png" alt="" v-if="item.id=='1' && tabIndex!='1'">
              <img src="@/assets/exam/chapter_block.png" alt="" v-if="item.id=='1' && tabIndex=='1'">

              <img src="@/assets/exam/Special.png" alt="" v-if="item.id=='2' && tabIndex!='2'">
              <img src="@/assets/exam/Special_block.png" alt="" v-if="item.id=='2' && tabIndex=='2'">

              <img src="@/assets/exam/sat_exam.png" alt="" v-if="item.id=='3' && tabIndex!='3'">
              <img src="@/assets/exam/sat_exam_block.png" alt="" v-if="item.id=='3' && tabIndex=='3'">

              <img src="@/assets/exam/sat_exam.png" alt="" v-if="item.id=='9' && tabIndex!='9'">
              <img src="@/assets/exam/sat_exam_block.png" alt="" v-if="item.id=='9' && tabIndex=='9' ">

              <img src="@/assets/exam/simulate.png" alt="" v-if="item.id=='4' && tabIndex!='4'">
              <img src="@/assets/exam/simulate_block.png" alt="" v-if="item.id=='4' && tabIndex=='4'">

              <img src="@/assets/exam/test.png" alt="" v-if="item.id=='5' && tabIndex!='5'">
              <img src="@/assets/exam/test_block.png" alt="" v-if="item.id=='5' && tabIndex=='5'">

              <img src="@/assets/exam/record.png" alt="" v-if="item.id=='6' && tabIndex!='6'">
              <img src="@/assets/exam/record_block.png" alt="" v-if="item.id=='6' && tabIndex=='6'">

              <img src="@/assets/exam/collect.png" alt="" v-if="item.id=='7' && tabIndex!='7'">
              <img src="@/assets/exam/collect_block.png" alt="" v-if="item.id=='7' && tabIndex=='7'">

              <img src="@/assets/exam/mistakes.png" alt="" v-if="item.id=='8' && tabIndex!='8'">
              <img src="@/assets/exam/mistakes_block.png" alt="" v-if="item.id=='8' && tabIndex=='8'">

              <p>{{ item.name }}</p>
              <img  class="examcenterdiv_center_left_tab_item_itemImg" style="width:77px;height: 43px;" src="@/assets/tiku/hoTwo.png" v-if="item.isHot" alt="" >
              
            </div>
            <div class="examcenterdiv_center_left_tab_item" v-if="selectfirstId != 245" v-for="(item,indexs) in sidebarList_noFirst" :key="indexs" @click="tabChange(item)" :class="tabIndex==item.id?'tabIndex':''">
              <img src="@/assets/exam/chapter.png" alt="" v-if="item.id=='1' && tabIndex!='1'">
                <img src="@/assets/exam/chapter_block.png" alt="" v-if="item.id=='1' && tabIndex=='1'">

                <img src="@/assets/exam/Special.png" alt="" v-if="item.id=='2' && tabIndex!='2'">
                <img src="@/assets/exam/Special_block.png" alt="" v-if="item.id=='2' && tabIndex=='2'">

                <img src="@/assets/exam/sat_exam.png" alt="" v-if="item.id=='3' && tabIndex!='3'">
                <img src="@/assets/exam/sat_exam_block.png" alt="" v-if="item.id=='3' && tabIndex=='3'">

                <img src="@/assets/exam/simulate.png" alt="" v-if="item.id=='4' && tabIndex!='4'">
                <img src="@/assets/exam/simulate_block.png" alt="" v-if="item.id=='4' && tabIndex=='4'">

                <img src="@/assets/exam/test.png" alt="" v-if="item.id=='5' && tabIndex!='5'">
                <img src="@/assets/exam/test_block.png" alt="" v-if="item.id=='5' && tabIndex=='5'">

                <img src="@/assets/exam/record.png" alt="" v-if="item.id=='6' && tabIndex!='6'">
                <img src="@/assets/exam/record_block.png" alt="" v-if="item.id=='6' && tabIndex=='6'">

                <img src="@/assets/exam/collect.png" alt="" v-if="item.id=='7' && tabIndex!='7'">
                <img src="@/assets/exam/collect_block.png" alt="" v-if="item.id=='7' && tabIndex=='7'">

                <img src="@/assets/exam/mistakes.png" alt="" v-if="item.id=='8' && tabIndex!='8'">
                <img src="@/assets/exam/mistakes_block.png" alt="" v-if="item.id=='8' && tabIndex=='8'">

                <p>{{ item.name }}</p>
              
            </div>
          </div>
          <div class="examcenterdiv_center_left_img">
            <!-- <div class="examcenterdiv_center_left_img_div">
              <p><img src="@/assets/exam/quiz.png" alt=""><span>免费提问</span></p>
              <p><img src="@/assets/exam/consult.png" alt=""><span>急速解答</span></p>
              <p><img src="@/assets/exam/alarm.png" alt=""><span>实时查看</span></p>
            </div> -->
            <img class="examcenterdiv_center_left_img_code" :src="website.qrCodeRight" alt />
            <p class="examcenterdiv_center_left_img_p">下载APP，海量题库随时刷</p>
          </div>
          <!-- <div class="examcenterdiv_center_left_rank">
            <p class="examcenterdiv_center_left_rank_title">每月排名</p>
            <div class="examcenterdiv_center_left_rank_item" v-for="(item,index) in questionBank_info.rankingList" :key="index">
              <div>
                <img src="@/assets/exam/one.png" alt="" v-if="index==0">
                <img src="@/assets/exam/two.png" alt="" v-if="index==1">
                <img src="@/assets/exam/three.png" alt="" v-if="index==2">
               
              </div>
              <div>
                <img :src="item.userHead" alt="">
                <p><span>{{ item.userName }}</span><span>做题数量{{ item.questionsNumber }}题</span></p>
              </div>
            </div>
          </div> -->
        </div>
        <div class="examcenterdiv_center_right">
          <!-- 章节练习,专项练习 -->
          <div class="examcenterdiv_center_right_chapter" v-if="tabIndex=='1' || tabIndex=='2' || tabIndex=='3' || tabIndex=='4' || tabIndex=='5'|| tabIndex=='9'">
            <div class="examcenterdiv_center_right_chapter_title">
              <p>{{ titleName }}</p>
              <p>已做/总数</p>
              <p>操作</p>
            </div>
            <el-menu default-active="2" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose" text-color="#333" active-text-color="#ffd04b" v-if="tabIndex=='1' || tabIndex=='2'">
              <el-submenu v-for="(item,index) in chapterList" :key="index" :index="JSON.stringify(index)">
                <template slot="title">
                  <p style="display:flex;width:100%;align-items: center;border-bottom: 1px solid #eee;height: 50px;">
                    <img src="@/assets/exam/add.png" alt="" style="width:20px;height:20px;margin-right:10px;" v-if="item.showIcon==false">
                    <img src="@/assets/exam/sub.png" alt="" style="width:20px;height:20px;margin-right:10px;" v-if="item.showIcon==true">
                    <span>{{ item.chapterName }}</span>
                  </p>
                </template>
                <el-menu-item v-for="(chapterListItem,key) in item.nodeList" :key="key" :index="JSON.stringify(index+'-'+key)">
                  <div class="" style="display:flex;justify-content: space-between;align-items: center;align-items: center;border-bottom: 1px solid #eee;">
                    <p style="width:400px;text-align:left;">{{ chapterListItem.nodeName }}</p>
                    <p style="width: 100px;display: flex;flex-flow: column;">
                      <el-progress :percentage="chapterListItem.isJiaojuan==1 ? 100 : chapterListItem.percentages" :show-text="false"></el-progress>
                      <span v-if="chapterListItem.isJiaojuan==0" style="text-align: left;padding: 0 30px;box-sizing: border-box;height: 30px;display: flex;align-items: center;">{{ chapterListItem.completeNumber }}/{{
                          chapterListItem.countNumber
                        }}
                      </span>
                      <span v-else style="text-align: left;padding: 0 30px;box-sizing: border-box;height: 30px;display: flex;align-items: center;">{{ chapterListItem.countNumber }}/{{
                          chapterListItem.countNumber
                        }}
                      </span>
                    </p>
                    <!-- 没有交卷并且完成数量大于0显示继续做题 -->
                    <p style="width:312px;display: flex;justify-content: flex-end;">
                      <el-button type="primary" size="small" round @click="doExercise(chapterListItem,1)" v-if="chapterListItem.completeNumber>0 && chapterListItem.isJiaojuan==0">
                        继续做题
                      </el-button>
                      <el-button type="primary"  size="small" round @click="doExercise(chapterListItem,1)" v-if="chapterListItem.completeNumber==0 && chapterListItem.countNumber>0">开始做题
                      </el-button>

                      <el-button round  size="small" @click="doExercise(chapterListItem,2)" v-if="chapterListItem.isJiaojuan==1">重新做题
                      </el-button>

                      <el-button type="primary" round  size="small" @click="lookReport(chapterListItem,1)" v-if="chapterListItem.isJiaojuan==1">查看报告
                      </el-button>
                    </p>
                  </div>
                </el-menu-item>
              </el-submenu>
            </el-menu>
            <div class="examcenterdiv_center_right_chapter_list" v-if="tabIndex=='3' || tabIndex=='4' || tabIndex=='5' || tabIndex=='9' ">
              <div class="examcenterdiv_center_right_chapter_list_item" v-for="(item,index) in chapterList" :key="index">
                <p style="width:400px;text-align:left;">
                  {{ item.testPaperName }}
                </p>
                <p style="width: 100px;display: flex;flex-flow: column;">
                  <el-progress :percentage="item.isJiaojuan==1 ? 100 : item.percentages" :show-text="false"></el-progress>
                  <span v-if="item.isJiaojuan==1 && (tabIndex=='3' || tabIndex=='4' || tabIndex=='5' || tabIndex=='9')" style="text-align: left;padding: 0 30px;box-sizing: border-box;height: 30px;display: flex;align-items: center;">
                    {{ item.countNumber }}/{{ item.countNumber }}
                  </span>
                  <span v-else style="text-align: left;padding: 0 30px;box-sizing: border-box;height: 30px;display: flex;align-items: center;">
                    {{ item.completeNumber }}/{{ item.countNumber }}
                  </span>
                </p>
                <p style="width:312px;display: flex;justify-content: flex-end;">
                  <el-button type="primary" round  size="small" @click="doExercise(item,1)" v-if="item.completeNumber>0 && item.isJiaojuan==0 ">继续做题
                  </el-button>
                  <el-button type="primary" round  size="small" @click="doExercise(item,1)" v-if="item.completeNumber==0 && item.countNumber>0">开始做题
                  </el-button>
                  <el-button type="primary" round   size="small" @click="lookReport(item,2)" v-if="item.isJiaojuan==1 && (tabIndex=='3' || tabIndex=='4' || tabIndex=='5'  || tabIndex=='9' )">查看报告
                  </el-button>
                  <el-button round  size="small" @click="doExercise(item,2)" v-if="item.isJiaojuan==1 && (tabIndex=='3' || tabIndex=='4' || tabIndex=='5'  || tabIndex=='9')">重新做题
                  </el-button>

                </p>
              </div>
            </div>

          </div>
          <div class="examcenterdiv_center_right_chapter" v-if="tabIndex=='6' || tabIndex=='8'">
            <el-tabs v-model="tabTwoIindex" @tab-click="handleTwoClick">
              <el-tab-pane label="章节" name="0">
                <el-menu default-active="2" class="el-menu-vertical-demo" @open="handleOpen_test" @close="handleClose_test" text-color="#333" active-text-color="#ffd04b">
                  <el-submenu v-for="(item,index) in tabChapterList" :key="index" :index="JSON.stringify(index)">
                    <template slot="title">
                      <div class="" style="display:flex;">
                        <p style="display:flex;width:80%;align-items: center;border-bottom: 1px solid #eee;height: 50px;">
                          <img src="@/assets/exam/add.png" alt="" style="width:20px;height:20px;margin-right:10px;" v-if="item.showIcon==false">
                          <img src="@/assets/exam/sub.png" alt="" style="width:20px;height:20px;margin-right:10px;" v-if="item.showIcon==true">
                          <span>{{ item.chapterName }}</span>
                        </p>
                        <p style="color:#333333;" v-if="tabIndex=='8'">错题{{ item.errorNumber }}</p>
                      </div>

                    </template>
                    <el-menu-item v-for="(chapterListItem,key) in item.nodeList" :key="key" :index="JSON.stringify(index+'-'+key)">
                      <div class="" style="display:flex;justify-content: space-between;align-items: center;border-bottom: 1px solid #eee;">
                        <p style="width:400px;text-align:left;">{{ chapterListItem.nodeName }}</p>
                        <p style="width: 100px;display: flex;flex-flow: column;align-items: center;" v-if="tabIndex=='6'">
                          <el-progress :percentage="chapterListItem.completeNumber>0 ? 100 : chapterListItem.percentages" :show-text="false"></el-progress>
                          <span v-if="chapterListItem.completeNumber>0" style="text-align: left;padding: 0 30px;box-sizing: border-box;height: 30px;display: flex;align-items: center;">{{
                              chapterListItem.countNumber
                            }}/{{ chapterListItem.countNumber }}</span>
                          <span v-else style="text-align: left;padding: 0 30px;box-sizing: border-box;height: 30px;display: flex;align-items: center;">{{
                              chapterListItem.completeNumber
                            }}/{{ chapterListItem.countNumber }}</span>
                        </p>
                        <p style="width:312px;display: flex;justify-content: flex-end;align-items: center;" v-if="tabIndex=='6'">
                          <el-button type="primary" round  size="small" @click="lookReport(chapterListItem,1)" v-if="chapterListItem.completeNumber>0">查看报告</el-button>
                          <el-button round  size="small" @click="doExercise(chapterListItem,2)">再次练习</el-button>
                        </p>
                        <p style="width:312px;display: flex;justify-content: flex-end;align-items: center;" v-if="tabIndex=='8'">
                          <span style="margin-right:20px;">{{ chapterListItem.errorNumber }}</span>
                          <el-button type="danger"  size="small" @click="goErrorTest(chapterListItem,1)" style="padding:0;width:70px;height:25px;">重做
                          </el-button>
                        </p>
                      </div>
                    </el-menu-item>
                  </el-submenu>
                </el-menu>
              </el-tab-pane>
              <el-tab-pane label="考试" name="1">
                <div class="examcenterdiv_center_right_chapter_list">
                  <div class="examcenterdiv_center_right_chapter_list_item" v-for="(item,index) in tabExamList" :key="index">
                    <p style="width:400px;text-align:left;">
                      {{ item.testPaperName }}
                    </p>
                    <p style="width: 100px;display: flex;flex-flow: column;">
                      <el-progress :percentage=" item.isJiaojuan == 1 ? 100 : ( parseInt(item.completeNumber) / parseInt(item.countNumber))" :show-text="false"></el-progress>
                      <span v-if="item.isJiaojuan == 0" style="text-align: left;padding: 0 30px;box-sizing: border-box;height: 30px;display: flex;align-items: center;">
                        {{item.completeNumber}}/{{ item.countNumber }}</span>
                      <span v-else style="text-align: left;padding: 0 30px;box-sizing: border-box;height: 30px;display: flex;align-items: center;">
                        {{item.countNumber}}/{{ item.countNumber }}</span>
                    </p>
                    <p style="width:312px;display: flex;justify-content: flex-end;align-items: center;" v-if="tabIndex=='6'">
                      <el-button type="primary" round @click="lookReport(item,2)" v-if="item.isJiaojuan == 1">查看报告</el-button>
                      <el-button round  size="small" @click="doExercise(item,2)">再次练习</el-button>
                    </p>
                    <p style="width:312px;display: flex;justify-content: flex-end;align-items: center;" v-if="tabIndex=='8'">
                      <span style="margin-right:10px;">错题{{ item.errorNumber }}</span>
                      <el-button type="danger"  size="small" @click="goErrorTest(item,2)" style="padding:0;width:70px;height:25px;">
                        重做
                      </el-button>
                    </p>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>

          </div>
          <div class="examcenterdiv_center_right_chapter" v-if="tabIndex=='7'" style="border:0;">
            <div class="examcenterdiv_center_right_chapter_header">
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/examination' }">在线题库</el-breadcrumb-item>
                <el-breadcrumb-item>习题收藏</el-breadcrumb-item>
              </el-breadcrumb>
              <span class="" @click="pruge()">清空收藏</span>
            </div>
            <div class="examcenterdiv_center_right_chapter_collect">
              <div class="examcenterdiv_center_right_chapter_collect_select">
                <span>题型</span>
                <el-select v-model="classifyValue" placeholder="请选择" @change="collectClassIty(classifyValue)">
                  <el-option v-for="item in classifyList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </div>
              <div class="examcenterdiv_center_right_chapter_collect_item" v-for="(item,index) in collectList" :key="index">
                <div class="examcenterdiv_center_right_chapter_collect_item_title" v-if="item.classify==1">【单选题】</div>
                <div class="examcenterdiv_center_right_chapter_collect_item_title" v-if="item.classify==2">【多选题】</div>
                <div class="examcenterdiv_center_right_chapter_collect_item_title" v-if="item.classify==3">【判断题】</div>
                <div class="examcenterdiv_center_right_chapter_collect_item_title" v-if="item.classify==4">【简答题】</div>
                <div class="examcenterdiv_center_right_chapter_collect_item_title" v-if="item.classify==5">【综合题】</div>
                <div class="examcenterdiv_center_right_chapter_collect_item_view">
                  <div class="examcenterdiv_center_right_chapter_collect_item_view_text">{{ item.subjectTitle }}</div>
                  <div class="examcenterdiv_center_right_chapter_collect_item_view_wire"></div>
                  <div class="examcenterdiv_center_right_chapter_collect_item_view_btn" @click="goCollectDetail(item)">
                    查看收藏
                  </div>
                </div>
                <!-- <div class="examcenterdiv_center_right_chapter_collect_item_explain">
                    <div class="examcenterdiv_center_right_chapter_collect_item_explain_left">
                        <p>
                            <span>共</span><span>29</span><span>道题</span>
                        </p>
                        <p>
                            <span>做题</span><span>1</span><span>道</span>
                        </p>
                    </div>
                    <div class="examcenterdiv_center_right_chapter_collect_item_explain_time">
                        <img src="" alt="">
                        <span>13:54</span>
                    </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
    <t_footer></t_footer>
  </div>
</template>

<script>
import t_header from '@/components/t_header/index.vue'
import t_footer from '@/components/t_footer/index.vue'
import {
  firstOrSecondList,
  secondOrList,
  exerciseList,
  test_paper,
  analysis_list,
  test_paperList,
  collect_list,
  error_subject_chapterList,
  exercise_subject_chapter,
  exercise_subject_paper,
  error_subject_paperList,
  subject_delCollect
} from "@/api/exam";
import { wangzhanpeizhi } from "@/api/login";

export default {
  name: 'examcenter',
  components: {
    t_header,
    t_footer,
  },
  data() {
    return {
      options: [],
      selectValue: '',
      selectfirstId:'',
      secondId: '',
      firstId: '',
      isSort: false,
      activeName: '0',
      second_typeList: [],
      questionBank_info: {},
      type: '1',
      chapterList: [],
      tabIndex: '1',
      sidebarList: [
        {
          id: '1',
          name: '章节练习'
        },
        // {
        //   id: '2',
        //   name: '专项练习'
        // },
        {
          id: '3',
          name: '历年真题'
        },
        {
          id: '4',
          name: '模拟试卷'
        },
        {
          id: '5',
          name: '考前押题'
        },
        {
          id: '9',
          name: '机考系统',
          isHot:true
        },
        {
          id: '6',
          name: '做题记录'
        },
        {
          id: '7',
          name: '习题收藏'
        },
        {
          id: '8',
          name: '错题本儿'
        },
      ],
      typeObjList:null,
      sidebarList_noFirst: [
        {
          id: '1',
          name: '章节练习'
        },
        // {
        //   id: '2',
        //   name: '专项练习'
        // },
        {
          id: '3',
          name: '历年真题'
        },
        {
          id: '4',
          name: '模拟试卷'
        },
        {
          id: '5',
          name: '考前押题'
        },
        {
          id: '6',
          name: '做题记录'
        },
        {
          id: '7',
          name: '习题收藏'
        },
        {
          id: '8',
          name: '错题本儿'
        },
      ],
      titleName: '章节练习',
      classify: 0,
      tabTwoIindex: 0,
      tabExamList: [],
      tabChapterList: [],
      collectList: [],
      classifyList: [
        {
          id: '1',
          name: '单选'
        },
        {
          id: '2',
          name: '多选'
        },
        {
          id: '3',
          name: '判断题'
        },
        {
          id: '4',
          name: '简答'
        },
        {
          id: '5',
          name: '综合'
        },
      ],
      classifyValue: '1',
      page: 0,
      isUserInfo: false,
      website: {},
    }
  },
  mounted() {
    this.secondId = this.$route.query.secondId
    this.firstId = this.$route.query.firstId
    this.firstOrSecondList()
    var obj = {
      secondId: this.secondId,
      type: this.tabIndex
    }
    this.exercise_list(obj)
    this.wangzhanpeizhi();
  },
  watch: {
    $route(to, from) {
      console.log(from.path);//从哪来
      console.log(to.path);//到哪去
    },
  },
  methods: {
    // 父传子
    getTodos(e) {
      console.log(e)
      this.isUserInfo = e
    },
    // 查看报告
    lookReport(item, num) {
      const userInfo = this.checkLogin();
      if (!userInfo) {
        return false
      }
      var that = this
      console.log('item', item)
      var testReportInfo = {
        examInfo: item,
        num: num,
        twoIindex: that.twoIindex,
        tabIndex: that.tabIndex,
        firstId: that.firstId,
        secondId: that.secondId
      }
      if (num == 1) {
        testReportInfo.nodeId = item.nodeId
        testReportInfo.nodeType = that.tabIndex
        // testReportInfo.firstId = that.firstId
        // testReportInfo.secondId = that.secondId
      } else {
        testReportInfo.testPaperId = item.testPaperId
      }
      localStorage.setItem("testReport", JSON.stringify(testReportInfo))
      that.$router.push({
        path: '/tikuReport',
        query: {
          nodeId: item.nodeId,
          nodeType: that.tabIndex,
          testPaperId: item.testPaperId,
        }
      });
    },
    collectClassIty(value) {
      this.classifyValue = value
      this.page = 0
      this.collect_list()
    },
    // 清空收藏
    pruge() {
      var arr = []
      for (var i in this.collectList) {
        arr.push(this.collectList[i].subjectId)
      }
      var subjectIds = arr.join(',')
      var obj = {
        subjectIds: subjectIds
      }
      this.subject_delCollect(obj)
    },
    // 移除收藏
    subject_delCollect(obj) {
      subject_delCollect(obj).then(res => {
        console.log(res)
        if (res.data.code == 0) {
          this.collectList = []
          this.page = this.page + 1
          this.collect_list()
        }
      }).catch(error => {
        console.log(error)
      })
    },
    goCollectDetail(item) {
      this.$router.push({
        path: '/collectPage',
        query: {
          subjectId: item.subjectId,
          classify: item.classify
        }
      });
    },
    tabChange(item) {
      console.log("====",item);
      this.tabIndex = item.id
      this.titleName = item.name
      var obj = {
        secondId: this.secondId,
        type: item.id
      }
      if (item.id == '1' || item.id == '2') {
        this.exercise_list(obj)
      } else if (item.id == '3' || item.id == '4' || item.id == '5' || item.id == '9') {
        if (item.id == '3') {
          this.classify = 2
        }else if (item.id == '9') {
          this.classify = 4
        } else if (item.id == '4') {
          this.classify = 1
        } else {
          this.classify = 3
        }
        this.test_paperList()
      } else {
        if (item.id == '6') {
          this.exercise_subject_chapter()
        } else if (item.id == '7') {
          this.collect_list()
        } else {
          this.error_subject_chapterList()
        }
      }
      console.log(item.id, this.classify);
    },
    // 做题记录章节列表
    exercise_subject_chapter() {
      var that = this
      var obj = {
        secondId: that.secondId
      }
      exercise_subject_chapter(obj).then(res => {
        if (res.data.code == 0) {
          var arr = res.data.data;
          for (var i in arr) {
            arr[i].showIcon = false;
            for (var j in arr[i].nodeList) {
              if (arr[i].nodeList[j].completeNumber <= 0) {
                arr[i].nodeList[j].percentages = 0
              } else if (arr[i].nodeList[j].countNumber == 0) {
                arr[i].nodeList[j].percentages = 0
              } else {
                arr[i].nodeList[j].percentages = Number(Number((arr[i].nodeList[j].completeNumber / arr[i].nodeList[j].countNumber) * 100).toFixed(2))
              }
            }
          }
          that.tabChapterList = arr
        } else {
          that.$message.error(res.data.msg)
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 做题记录考试列表
    exercise_subject_paper() {
      var that = this
      var obj = {
        secondId: this.secondId
      }
      exercise_subject_paper(obj).then(res => {
        if (res.data.code == 0) {
          // this.tabExamList=res.data.data
          var arr = res.data.data
          for (var i in arr) {
            arr[i].showIcon = false
            if (arr[i].completeNumber <= 0) {
              arr[i].percentages = 0
            } else if (arr[i].countNumber == 0) {
              arr[i].percentages = 0
            } else {
              arr[i].percentages = Number(Number((arr[i].completeNumber / arr[i].countNumber) * 100).toFixed(2))
            }
          }
          that.tabExamList = arr;
        } else {
          this.$message.error(res.data.msg)
        }
      }).catch(error => {
        console.log(error)
      })
    },

    // 习题收藏列表
    collect_list() {
      var obj = {
        classify: this.classifyValue,
        page: this.page,
        pageSize: 10
      }
      collect_list(obj).then(res => {
        if (res.data.code == 0) {
          this.collectList = res.data.data
        } else {
          this.$message.error(res.data.msg)
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 错题本章节列表
    error_subject_chapterList() {
      var that = this
      var obj = {
        secondId: this.secondId
      }
      error_subject_chapterList(obj).then(res => {
        if (res.data.code == 0) {
          var arr = res.data.data;
          let new_arr = new Array();
          for (var i in arr) {
            if(parseInt(arr[i].errorNumber ) > 0){
              arr[i].showIcon = false
              for (var j in arr[i].nodeList) {
                if (arr[i].nodeList[j].completeNumber <= 0) {
                  arr[i].nodeList[j].percentages = 0
                } else if (arr[i].nodeList[j].countNumber == 0) {
                  arr[i].nodeList[j].percentages = 0
                } else {
                  arr[i].nodeList[j].percentages = Number(Number((arr[i].nodeList[j].completeNumber / arr[i].nodeList[j].countNumber) * 100).toFixed(2))
                }
              }
              new_arr.push(arr[i]);

            }
            
          }
          that.tabChapterList = new_arr;
        } else {
          that.$message.error(res.data.msg)
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 错题本考试列表
    error_subject_paperList() {
      var obj = {
        secondId: this.secondId
      }
      error_subject_paperList(obj).then(res => {
        if (res.data.code == 0) {
          this.tabExamList = res.data.data
        } else {
          this.$message.error(res.data.msg)
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 进度条的文字内容显示
    format_one() {
      return `${this.questionBank_info.countNumber || 0}`;
    },
    format_two(percentage) {
      return `${percentage}%`;
    },
    // 去题详情
    doExercise(item, value) {
      const userInfo = this.checkLogin();
      if (!userInfo) {
        return false
      }
      var twoIindex = ''
      if (this.tabIndex == '6' || this.tabIndex == '8') {
        twoIindex = this.tabTwoIindex
      }
    
      if(this.tabIndex == '9'){
        let routeData = this.$router.resolve({
          path: '/mnlogin', query: {
            tabIndex: this.tabIndex,
            testPaperId: item.testPaperId,
            type:this.typeObjList.secondId
          }
        })
        localStorage.setItem("tiku_examText", JSON.stringify({
          tabIndex: this.tabIndex,
          chapterListItem: item,
          value: value,
          twoIindex: twoIindex,
          firstId: this.firstId,
          secondId: this.secondId
        }))

        window.open(routeData.href, '_blank');

      }else{
        let routeData = this.$router.resolve({
          path: '/tikuDetail', query: {
            tabIndex: this.tabIndex,
            testPaperId: item.testPaperId
          }
        })
        localStorage.setItem("tiku_examText", JSON.stringify({
          tabIndex: this.tabIndex,
          chapterListItem: item,
          value: value,
          twoIindex: twoIindex,
          firstId: this.firstId,
          secondId: this.secondId
        }))

        window.open(routeData.href, '_blank');

      }
      
    },
   
    selectChange(value) {
      this.selectfirstId = value;
      var obj = {
        firstId: value
      }
      this.secondOrList(obj);
    },
    handleClick(tab, event) {
      // console.log(this.second_typeList[tab.index].secondId)
      this.secondId = this.second_typeList[tab.index].secondId
      var obj = {
        secondId: this.second_typeList[tab.index].secondId
      }
      this.test_paper(obj)
      // }else
    },
    handleTwoClick(tab, event) {
      if (tab.index == '0') {
        if (this.tabIndex == '6') {
          this.exercise_subject_chapter()
        } else {
          this.error_subject_chapterList()
        }
      } else {
        if (this.tabIndex == '6') {
          this.exercise_subject_paper()
        } else {
          this.error_subject_paperList()
        }
      }
    },
    // 获取一级类
    firstOrSecondList() {
      firstOrSecondList().then(res => {
        if (res.data.code == 0) {
          this.options = res.data.data
          for (var i in res.data.data) {
            if (this.$route.query.firstId == res.data.data[i].firstId) {
              this.selectValue = res.data.data[i].firstName;
              
              this.second_typeList = res.data.data[i].secondList
            }
          }
          this.selectfirstId = this.$route.query.firstId;
          var obj = {
            secondId: this.second_typeList[0].secondId
          }
          this.test_paper(obj)
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 获取二级类
    secondOrList(obj) {
      secondOrList(obj).then(res => {
        if (res.data.code == 0) {
          this.second_typeList = res.data.data
          var obj = {
            secondId: res.data.data[0].secondId
          }
          this.secondId = res.data.data[0].secondId;
          this.test_paper(obj)
          var listObj = {
            secondId: res.data.data[0].secondId,
            type: this.tabIndex
          }
          this.exercise_list(listObj)
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 根据二级类进入首页
    test_paper(obj) {
      test_paper(obj).then(res => {
        if (res.data.code == 0) {
          var obj = res.data.data
          /*  if (res.data.data.answerNumber == 0 || res.data.data.countNumber == 0) {
              obj.accuracy = 0
              } else {
              obj.accuracy = Number(Number((res.data.data.answerNumber / res.data.data.countNumber) * 100).toFixed(2))
              }*/
          obj.answerNumber = Number(obj.answerNumber)
          obj.countNumber = Number(obj.countNumber)
          obj.errorAnswerNumber = Number(obj.errorAnswerNumber)
          obj.accuracy = Number(obj.accuracy)
          obj.totalRatio = Number(obj.answerNumber / obj.countNumber * 100)
          this.questionBank_info = obj

          var listObj = {
            type: this.tabIndex,
            secondId: res.data.data.secondId
          }
          // this.exercise_list(listObj)
          this.getMenuListItem(listObj);
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(error => {
        console.log(error)
      })
    },
    getMenuListItem(listObj) { 
      if (this.tabIndex == 4 || this.tabIndex == 3 || this.tabIndex == 5 || this.tabIndex == 9) {
        this.test_paperList();
      } else if(this.tabIndex == 6){
        this.exercise_subject_chapter();
        this.exercise_subject_paper();
      }else if(this.tabIndex == 7){
        this.collect_list();
      }else if(this.tabIndex == 8){
        this.error_subject_chapterList();
      }  else {
        this.exercise_list(listObj)
      }

    },
    // // 首页-章节/专项练习列表
    // exercise_list(obj){
    //     var that=this

    //     exercise_list(obj).then(res=>{
    //         console.log(res)
    //         if(res.data.code==0){
    //             var arr=res.data.data
    //             // if(this.tabIndex=='1' || this.tabIndex=='2'){
    //                 for(var i in arr){
    //                     arr[i].showIcon=false
    //                     for(var j in arr[i].nodeList){
    //                         if(arr[i].nodeList[j].completeNumber<=0){
    //                             arr[i].nodeList[j].percentages=0
    //                         }else{
    //                             arr[i].nodeList[j].percentages=Number(Number((arr[i].nodeList[j].completeNumber/arr[i].nodeList[j].countNumber)*100).toFixed(2))
    //                         }
    //                     }
    //                 }
    //             // }else if(this.tabIndex=='3' || this.tabIndex=='4' || this.tabIndex=='5'){
    //             //     for(var i in arr){
    //             //         if(arr[i].completeNumber<=0){
    //             //             arr[i].percentages=0
    //             //         }else if(arr[i].countNumber==0){
    //             //             arr[i].percentages=0
    //             //         }
    //             //         else{
    //             //             arr[i].percentages=Number(Number((arr[i].completeNumber/arr[i].nodeList[j].countNumber)*100).toFixed(2))
    //             //         }
    //             //     }
    //             // }

    //             that.chapterList=arr
    //         }
    //     }).catch(error=>{
    //         console.log(error)
    //     })
    // },
    // 首页-章节/专项练习列表
    exercise_list(obj) {
      var that = this

      exerciseList(obj).then(res => {
        console.log("obj",obj);
        console.log("res",res);
        this.typeObjList = obj;
        if (res.data.code == 0) {
          var arr = res.data.data
          for (var i in arr) {
            arr[i].showIcon = false
            for (var j in arr[i].nodeList) {
              if (arr[i].nodeList[j].completeNumber <= 0) {
                arr[i].nodeList[j].percentages = 0
              } else {
                arr[i].nodeList[j].percentages = Number(Number((arr[i].nodeList[j].completeNumber / arr[i].nodeList[j].countNumber) * 100).toFixed(2))
              }
            }
          }
          console.log("that.chapterList",arr);
          that.chapterList = arr;
          
        }
      }).catch(error => {
        console.log(error)
      })
    },
    //历年/模拟/考前试卷列表
    test_paperList() {
      var that = this
      var obj = {
        classify: that.classify,
        secondId: that.secondId,
      }
      test_paperList(obj).then(res => {
        if (res.data.code == 0) {
          var arr = res.data.data
          for (var i in arr) {
            arr[i].showIcon = false
            if (arr[i].completeNumber <= 0) {
              arr[i].percentages = 0
            } else {
              arr[i].percentages = Number(Number((arr[i].completeNumber / arr[i].countNumber) * 100).toFixed(2))
            }
          }
          that.chapterList = arr
        } else {
          that.$message.error(res.data.msg)
        }
      }).catch(error => {
        console.log(error)
      })
    },

    handleOpen(key, keyPath) {
      var obj = this.chapterList
      obj[key].showIcon = true
      this.chapterList = obj
    },
    handleClose(key, keyPath) {
      var obj = this.chapterList
      obj[key].showIcon = false
      this.chapterList = obj
    },
    handleOpen_test(key, keyPath) {
      var obj = this.tabChapterList
      obj[key].showIcon = true
      this.tabChapterList = obj
    },
    handleClose_test(key, keyPath) {
      console.log(key, keyPath);
      var obj = this.tabChapterList
      obj[key].showIcon = false
      this.tabChapterList = obj
    },
    // 点击重做
    goErrorTest(item, num) {
      const userInfo = this.checkLogin();
      if (!userInfo) {
        return false
      }
      this.$router.push({
        path: '/testError',
        query: {
          chapterListItem: JSON.stringify(item),
          tabTwoIindex: this.tabTwoIindex,
          num: num,
          tabIndex: this.tabIndex,
        }
      });
    },
    wangzhanpeizhi() {
      wangzhanpeizhi().then(res => {
        if (res.data.code == 0) {
          this.website = res.data.data;
        }
      }).catch(error => {
        console.log(error);
      })
    },
    checkLogin() {
      const userInfo = localStorage.getItem("setuserInfo");
      if (userInfo == null || userInfo == 'null') {
        this.isUserInfo = true;
        setTimeout(() => {
          this.isUserInfo = false;
        }, 0);
        return false
      } else {
        return userInfo
      }
    },
  }
}
</script>

<style>
</style>